import { AdvisorVariants } from '../advisors/Types'

export const getAdvisorCopy = (
  advisorVariant: AdvisorVariants,
  title: string,
  firstName: string
) => {
  return {
    heroEyebrow: getHeroEyebrow(advisorVariant),
    heroButton: getHeroButton(advisorVariant, firstName),
    slidingBoxCTAEyebrow: getSlidingBoxCTAEyebrow(advisorVariant),
    slidingBoxCTAcopy: getSlidingBoxCTAcopy(advisorVariant, title),
    slidingBoxCTAButton: getSlidingBoxCTAButton(advisorVariant, firstName),
    profileBodyMyTravelStyle: getProfileBodyMyTravelStyle(advisorVariant),
    profileBody: getProfileBody(advisorVariant),
    CTABoxBottom: getCTABoxBottom(advisorVariant, firstName),
    CTABoxCopy: getCTABoxCopy(advisorVariant, firstName),
    CTAbutton: getCTAbutton(advisorVariant, firstName),
    CTAeyebrow: getCTAeyebrow(advisorVariant),
  }
}

export const getHeroEyebrow = (advisorVariant: AdvisorVariants) => {
  let response = `Travel Advisor`
  switch (advisorVariant) {
    case AdvisorVariants.FORA:
      response = 'The Modern Travel Agency'
      break
    case AdvisorVariants.PARTNER:
      response = 'Partner'
      break
  }
  return response
}

export const getHeroButton = (
  advisorVariant: AdvisorVariants,
  firstName: string
) => {
  let response = `Contact ${firstName}`
  switch (advisorVariant) {
    case AdvisorVariants.FORA:
    case AdvisorVariants.PARTNER:
      response = 'Reach out'
      break
  }
  return response
}

export const getSlidingBoxCTAEyebrow = (advisorVariant: AdvisorVariants) => {
  let response = `Travel Advisor`
  switch (advisorVariant) {
    case AdvisorVariants.FORA:
      response = 'The Modern Travel Agency'
      break
    case AdvisorVariants.PARTNER:
      response = 'Partner'
      break
  }
  return response
}
export const getSlidingBoxCTAcopy = (
  advisorVariant: AdvisorVariants,
  title: string
) => {
  let response = `Reach out to customize and book your own experience, plus unlock exclusive perks & upgrades.`
  switch (advisorVariant) {
    case AdvisorVariants.FORA:
      response =
        'Reach out to connect with a Fora Advisor and book your own experience, plus unlock exclusive perks & upgrades.'
      break
    case AdvisorVariants.PARTNER:
      response = `${title} has partnered with Fora to bring you exclusive perks and expert advice. Book with Fora to get access.`
      break
  }
  return response
}
export const getSlidingBoxCTAButton = (
  advisorVariant: AdvisorVariants,
  firstName: string
) => {
  let response = `Contact ${firstName}`
  switch (advisorVariant) {
    case AdvisorVariants.FORA:
    case AdvisorVariants.PARTNER:
      response = 'Reach out'
      break
  }
  return response
}
export const getProfileBodyMyTravelStyle = (
  advisorVariant: AdvisorVariants
) => {
  let response = `My travel style`
  switch (advisorVariant) {
    case AdvisorVariants.FORA:
    case AdvisorVariants.PARTNER:
      response = 'Our travel style'
      break
  }
  return response
}
export const getProfileBody = (advisorVariant: AdvisorVariants) => {
  let response = `One thing I can't travel without...`
  switch (advisorVariant) {
    case AdvisorVariants.FORA:
    case AdvisorVariants.PARTNER:
      response = `One thing we can't travel without...`
      break
  }
  return response
}
export const getCTABoxBottom = (
  advisorVariant: AdvisorVariants,
  firstName: string
) => {
  let response = `Get in touch with ${firstName}`
  switch (advisorVariant) {
    case AdvisorVariants.FORA:
    case AdvisorVariants.PARTNER:
      response = 'Connect with a Fora Advisor'
      break
  }
  return response
}

export const getCTABoxCopy = (
  advisorVariant: AdvisorVariants,
  title: string
) => {
  let response = `Reach out to customize and book your own experience. Or, just to chat about travel in general.`
  switch (advisorVariant) {
    case AdvisorVariants.FORA:
      response =
        'Reach out to connect with a Fora Advisor and book your own experience, plus unlock exclusive perks & upgrades.'
      break
    case AdvisorVariants.PARTNER:
      response = `${title} has partnered with Fora to bring you exclusive perks and expert advice for your next trip. Connect with a Fora Advisor to customize and book your own experience.`
      break
  }
  return response
}

export const getCTAbutton = (
  advisorVariant: AdvisorVariants,
  firstName: string
) => {
  let response = `Contact ${firstName}`
  switch (advisorVariant) {
    case AdvisorVariants.FORA:
    case AdvisorVariants.PARTNER:
      response = 'Reach out'
      break
  }
  return response
}

export const getCTAeyebrow = (advisorVariant: AdvisorVariants) => {
  let response = `Travel Advisor`
  switch (advisorVariant) {
    case AdvisorVariants.FORA:
      response = 'The Modern Travel Agency'
      break
    case AdvisorVariants.PARTNER:
      response = 'Partner'
      break
  }
  return response
}
