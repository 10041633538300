import useBreakpoint from 'hooks/useBreakpoints'
import { ComponentSpacer } from 'types/generated/contentful-types'
import { Spacer, SpacerSize } from '../spacing/Spacer'
import { Divider } from '../ui/Divider'
import { isDesktop, isMobile } from '@/utils/Helpers'
import Image from 'next/image'

type SpacerComponentProps = Omit<
  ComponentSpacer,
  'contentfulMetadata' | 'sys' | '_id'
>

const SpacerComponent = ({
  paddingTop,
  paddingTopMobile,
  paddingBelow,
  paddingBelowMobile,
  hasDivider,
  icon,
  cloudinaryIcon,
}: SpacerComponentProps) => {
  const breakpoint = useBreakpoint()
  return (
    <>
      {paddingTop && isDesktop(breakpoint) && (
        <Spacer size={paddingTop as SpacerSize} />
      )}
      {paddingTopMobile && isMobile(breakpoint) && (
        <Spacer size={paddingTopMobile as SpacerSize} />
      )}
      {!icon && hasDivider && <Divider />}
      {icon && icon.url && (
        <div className="relative w-16 mx-auto" data-name="thumbnail">
          <Image
            src={cloudinaryIcon?.[0]?.secure_url ?? icon?.url}
            data-src={cloudinaryIcon?.[0]?.metadata?.alt || icon?.url}
            alt="Icon"
            width={60}
            height={60}
          />
        </div>
      )}
      {paddingBelow && isDesktop(breakpoint) && (
        <Spacer size={paddingBelow as SpacerSize} />
      )}
      {paddingBelowMobile && isMobile(breakpoint) && (
        <Spacer size={paddingBelowMobile as SpacerSize} />
      )}
    </>
  )
}

export default SpacerComponent
