import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { getAdvisorDisplayName } from '@/utils/Helpers'
import { Button } from '@/components/ui/Buttons'
import { ButtonLink } from '@/components/ui/ButtonLinks'
import { AdvisorContactForm } from '@/components/forms/AdvisorContactForm'
import { Modal } from '@/components/common/Modal'
import { AdvisorSignUpSuccessModal } from '@/components/forms/AdvisorSignUpSuccessModal'
import { AdvisorVariants, checkifIsProfile } from './Types'
import { getHeroEyebrow } from './Utils'
import { compatibleCloudinaryImageLoader } from '@/utils/ImageLoaders'

export type AdvisorContactCTAProps = {
  title: string
  slug: string
  advisorVariant?: AdvisorVariants
  emailAddress: string
  ctaText?: string
  ctaButtonText?: string
  firstName?: string
  lastName?: string
  image?: any
  CTABylineEyebrow?: string
  CTABodyText?: string
  CTAButtonText?: string
  formHeading?: string
  formSubheading?: string
  formSubmitButtonText?: string
  formAdvisorBylineEyebrow?: string
  compact?: boolean
  buttonOnly?: boolean
  showingFormModal?: boolean
  useDefaultPlaceHolderText?: boolean
  cloudinaryImage?: any
  advisorContactEnabled?: boolean
  setShowingFormModal?: (isVisible: boolean) => void
}

export type SharedAdvisorContactCTAProps = Pick<
  AdvisorContactCTAProps,
  | 'title'
  | 'slug'
  | 'advisorVariant'
  | 'firstName'
  | 'lastName'
  | 'image'
  | 'cloudinaryImage'
  | 'CTABylineEyebrow'
  | 'emailAddress'
  | 'formHeading'
  | 'formSubheading'
  | 'formSubmitButtonText'
  | 'formAdvisorBylineEyebrow'
  | 'advisorContactEnabled'
>

export type AdvisorContactDisabledCTAProps = SharedAdvisorContactCTAProps

const getAdvisorCTAButtonText = (title: string, firstName?: string) => {
  const displayName = getAdvisorDisplayName(title, firstName)
  return `Contact ${displayName}`
}

export function AdvisorContactDisabledCTA({
  title,
  slug,
  advisorVariant,
  firstName,
  lastName,
  CTABylineEyebrow,
  cloudinaryImage,
  emailAddress,
  formHeading,
  formSubheading,
  formSubmitButtonText,
  formAdvisorBylineEyebrow,
}: AdvisorContactDisabledCTAProps): JSX.Element {
  const [showingFormModal, setShowingFormModal] = React.useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showingSubmitFormModal, setShowingSubmitFormModal] =
    React.useState<boolean>(false)
  return (
    <section className="px-6 py-12 bg-shell">
      <Link
        href={
          checkifIsProfile(advisorVariant ?? AdvisorVariants.PERSON)
            ? `/profile/${slug}`
            : `/advisor/${slug}`
        }
        target="_self"
        className="flex pb-6"
        data-name="cta-link"
      >
        <div className="mr-3 w-14">
          {cloudinaryImage && (
            <Image
              loader={() =>
                compatibleCloudinaryImageLoader({
                  public_id: cloudinaryImage?.[0].public_id || '',
                  width: 640,
                  height: 640,
                  quality: 90,
                  fit: 'fill',
                })
              }
              className="rounded-full"
              src={cloudinaryImage?.[0]?.secure_url}
              data-src={cloudinaryImage?.[0]?.secure_url}
              alt={`Advisor - ${title}`}
              width={640}
              height={640}
              sizes="50vw"
              quality={90}
            />
          )}
        </div>
        <div>
          <h5 className="pb-0.5 fora-text-eyebrow-3 uppercase">
            {CTABylineEyebrow ?? 'Travel Advisor'}
          </h5>
          <h5 className="fora-text-h7">{title}</h5>
        </div>
      </Link>
      <p className="mb-6 fora-text-body-2" data-name="contact-info">
        {firstName} is not currently accepting new clientele. If you&apos;re a
        current client, reach out directly. If you&apos;re looking for a new
        advisor,{' '}
        <Link href="/advisors" className="underline">
          find one today
        </Link>
        .
      </p>
      <Modal
        onClose={() => setShowingFormModal(false)}
        visible={showingFormModal}
      >
        <AdvisorContactForm
          conversionComponentName="Advisor Contact Disabled CTA"
          title={title}
          firstName={firstName}
          lastName={lastName}
          emailAddress={emailAddress}
          cloudinaryImage={cloudinaryImage}
          showRightPanel={false}
          onSubmit={() => {
            setShowingFormModal(false)
            setShowingSubmitFormModal(true)
          }}
          heading={formHeading}
          subheading={formSubheading}
          submitButtonText={formSubmitButtonText}
          advisorBylineEyebrow={formAdvisorBylineEyebrow}
        />
      </Modal>
    </section>
  )
}

export type AdvisorContactProfileProps = { center?: boolean } & Pick<
  AdvisorContactCTAProps,
  | 'title'
  | 'slug'
  | 'advisorVariant'
  | 'image'
  | 'cloudinaryImage'
  | 'CTABylineEyebrow'
>

export const AdvisorContactProfile = ({
  title,
  slug,
  advisorVariant,
  cloudinaryImage,
  CTABylineEyebrow,
  center = false,
}: AdvisorContactProfileProps) => (
  <Link
    href={
      checkifIsProfile(advisorVariant ?? AdvisorVariants.PERSON)
        ? `/profile/${slug}`
        : `/advisor/${slug}`
    }
    target="_self"
    className={`flex ${center ? 'justify-center' : ''}`}
  >
    <div className="mr-3 w-14">
      {cloudinaryImage && (
        <Image
          loader={() =>
            compatibleCloudinaryImageLoader({
              public_id: cloudinaryImage?.[0].public_id || '',
              width: 640,
              height: 640,
              quality: 90,
              fit: 'fill',
            })
          }
          className="rounded-full"
          src={cloudinaryImage?.[0]?.secure_url}
          data-src={cloudinaryImage?.[0]?.secure_url}
          data-name="advisor-thumb"
          alt={`Advisor - ${title}`}
          layout="responsive"
          width={640}
          height={640}
          sizes="50vw"
          quality={90}
        />
      )}
    </div>
    <div>
      <h5 className="pb-0.5 fora-text-eyebrow-3 uppercase">
        {CTABylineEyebrow ?? 'Advisor'}
      </h5>
      <h5 className="fora-text-h7">{title}</h5>
    </div>
  </Link>
)

export function AdvisorContactCTA({
  title,
  slug,
  advisorVariant,
  emailAddress,
  firstName,
  lastName,
  cloudinaryImage,
  CTABylineEyebrow,
  CTABodyText,
  CTAButtonText,
  formHeading,
  formSubheading,
  formSubmitButtonText,
  formAdvisorBylineEyebrow,
  compact = false,
  buttonOnly = false,
  showingFormModal: externalShowingFormModal,
  useDefaultPlaceHolderText = false,
  setShowingFormModal: externalSetShowingFormModal,
}: AdvisorContactCTAProps): JSX.Element {
  const [localShowingFormModal, setLocalShowingFormModal] =
    React.useState<boolean>(false)
  const [showingSubmitFormModal, setShowingSubmitFormModal] =
    React.useState<boolean>(false)

  const setFormModalVisibility = externalSetShowingFormModal
    ? externalSetShowingFormModal
    : setLocalShowingFormModal

  const isFormModalVisible =
    externalShowingFormModal !== undefined
      ? externalShowingFormModal
      : localShowingFormModal

  const ctaBodyText =
    CTABodyText ??
    '“I’d love to chat and see how I can level up your travel experience.”'

  return (
    <>
      {buttonOnly ? (
        <Button
          buttonFn={() => setFormModalVisibility(true)}
          text={CTAButtonText ?? getAdvisorCTAButtonText(title, firstName)}
          theme="primary"
          isDisabled={false}
          isFull={true}
        />
      ) : (
        <section className="p-6 bg-shell">
          <div className={compact ? '' : 'pb-6 '}>
            <AdvisorContactProfile
              title={title}
              slug={slug}
              advisorVariant={advisorVariant}
              cloudinaryImage={cloudinaryImage}
              CTABylineEyebrow={CTABylineEyebrow}
              center={compact}
            />
          </div>
          <p className={`${compact ? 'mb-0' : 'mb-6'} fora-text-body-2`}>
            {ctaBodyText}
          </p>
          <div
            className={`flex justify-center ${
              compact ? 'w-[75%] m-auto' : 'w-full'
            }`}
          >
            <Button
              buttonFn={() => setFormModalVisibility(true)}
              text={CTAButtonText ?? getAdvisorCTAButtonText(title, firstName)}
              theme="primary"
              isDisabled={false}
              isFull={true}
            />
          </div>
        </section>
      )}

      <Modal
        onClose={() => setFormModalVisibility(false)}
        visible={isFormModalVisible}
      >
        <AdvisorContactForm
          conversionComponentName="Advisor Contact CTA"
          title={title}
          firstName={firstName}
          lastName={lastName}
          emailAddress={emailAddress}
          cloudinaryImage={cloudinaryImage}
          showRightPanel={false}
          onSubmit={() => {
            setFormModalVisibility(false)
            setShowingSubmitFormModal(true)
          }}
          heading={formHeading}
          subheading={formSubheading}
          submitButtonText={formSubmitButtonText}
          advisorBylineEyebrow={formAdvisorBylineEyebrow}
          useDefaultPlaceHolderText={useDefaultPlaceHolderText}
        />
      </Modal>

      <Modal
        onClose={() => setShowingSubmitFormModal(false)}
        visible={showingSubmitFormModal}
      >
        <AdvisorSignUpSuccessModal
          onClose={() => setShowingSubmitFormModal(false)}
          displayName={getAdvisorDisplayName(title, firstName)}
        />
      </Modal>
    </>
  )
}

export function AdvisorContactCTAMobile({
  title,
  firstName,
  lastName,
  emailAddress,
  advisorVariant,
  cloudinaryImage,
  advisorContactEnabled,
  useDefaultPlaceHolderText = false,
}: AdvisorContactCTAProps): JSX.Element {
  const [showingFormModal, setShowingFormModal] = React.useState<boolean>(false)
  const [showingSubmitFormModal, setShowingSubmitFormModal] =
    React.useState<boolean>(false)

  return (
    <>
      <div className="flex justify-between p-4 bg-shell">
        <div className="flex items-center">
          <div className="w-10 mr-2">
            {cloudinaryImage && (
              <Image
                loader={() =>
                  compatibleCloudinaryImageLoader({
                    public_id: cloudinaryImage?.[0].public_id || '',
                    width: 640,
                    height: 640,
                    quality: 90,
                    fit: 'fill',
                  })
                }
                className="rounded-full"
                src={cloudinaryImage?.[0]?.secure_url}
                data-src={cloudinaryImage?.[0]?.secure_url}
                alt={`Advisor - ${title}`}
                width={640}
                height={640}
                sizes="50vw"
                objectFit="cover"
                layout="responsive"
                quality={90}
              />
            )}
          </div>
          <div className="">
            <h3 className="fora-text-eyebrow-4">
              {getHeroEyebrow(advisorVariant || AdvisorVariants.PERSON)}
            </h3>
            <h2 className="fora-text-h7">{title}</h2>
          </div>
        </div>
        {advisorContactEnabled && (
          <div>
            <Button
              buttonFn={() => setShowingFormModal(true)}
              text="Contact"
              theme="primary"
              isDisabled={false}
              isFull={true}
            />
          </div>
        )}
      </div>
      <Modal
        onClose={() => setShowingFormModal(false)}
        visible={showingFormModal}
      >
        <AdvisorContactForm
          conversionComponentName="Advisor Contact CTA Mobile"
          useDefaultPlaceHolderText={useDefaultPlaceHolderText}
          title={title}
          firstName={firstName}
          lastName={lastName}
          emailAddress={emailAddress}
          cloudinaryImage={cloudinaryImage}
          showRightPanel={false}
          onSubmit={() => {
            setShowingFormModal(false)
            setShowingSubmitFormModal(true)
          }}
        />
      </Modal>
      <Modal
        onClose={() => setShowingSubmitFormModal(false)}
        visible={showingSubmitFormModal}
      >
        <AdvisorSignUpSuccessModal
          onClose={() => setShowingSubmitFormModal(false)}
          displayName={getAdvisorDisplayName(title, firstName)}
        />
      </Modal>
    </>
  )
}

export function AdvisorContactCTAWide({
  title,
  firstName,
  lastName,
  ctaText,
  ctaButtonText,
  emailAddress,
  cloudinaryImage,
}: AdvisorContactCTAProps): JSX.Element {
  const [showingFormModal, setShowingFormModal] = React.useState<boolean>(false)
  const [showingSubmitFormModal, setShowingSubmitFormModal] =
    React.useState<boolean>(false)
  return (
    <section className="p-6 text-center lg:p-8 bg-shell">
      <div
        className="items-center justify-center pb-8 lg:flex"
        data-name="cta-content"
      >
        <p className="md:fora-text-h7 fora-text-h5">
          {ctaText ??
            `Unlock perks by contacting 
          ${getAdvisorDisplayName(title, firstName)} to book your trip.`}
        </p>
      </div>
      <div>
        <Button
          buttonFn={() => setShowingFormModal(true)}
          text={ctaButtonText ?? getAdvisorCTAButtonText(title, firstName)}
          theme="primary"
          isDisabled={false}
        />
      </div>
      <Modal
        onClose={() => setShowingFormModal(false)}
        visible={showingFormModal}
      >
        <AdvisorContactForm
          conversionComponentName="Advisor Contact CTA Wide"
          title={title}
          firstName={firstName}
          lastName={lastName}
          emailAddress={emailAddress}
          cloudinaryImage={cloudinaryImage}
          showRightPanel={false}
          onSubmit={() => {
            setShowingFormModal(false)
            setShowingSubmitFormModal(true)
          }}
        />
      </Modal>
      <Modal
        onClose={() => setShowingSubmitFormModal(false)}
        visible={showingSubmitFormModal}
      >
        <AdvisorSignUpSuccessModal
          onClose={() => setShowingSubmitFormModal(false)}
          displayName={getAdvisorDisplayName(title, firstName)}
        />
      </Modal>
    </section>
  )
}

type BecomeAnAdvisorCTAProps = {
  isSolid?: boolean
}

export function BecomeAnAdvisorCTA({
  isSolid = false,
}: BecomeAnAdvisorCTAProps): JSX.Element {
  return (
    <section className={`p-6 mb-6 ${isSolid ? 'bg-shell' : 'border'}`}>
      <h2 className="pb-6 fora-text-h6">Become an advisor</h2>
      <p className="pb-2 fora-text-body-2">
        Are you the go-to person for travel tips? Transform your passion into
        your dream job by becoming a Fora Travel Advisor.
      </p>
      <div>
        <ButtonLink
          text="Learn More"
          target="_self"
          href="/join-us"
          theme={`${isSolid ? 'primary' : 'secondary'}`}
          isFull={true}
        />
      </div>
    </section>
  )
}
